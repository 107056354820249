import React, { useContext } from 'react';

import './featuresSection.modules.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FeaturesSectionProps } from '../../../types/types';
import processFeaturesSectionData from './helper';
import PageContext from '../../../context/page/pageContext';

const FeaturesSection = (props: FeaturesSectionProps) => {
	const { data, page } = useContext(PageContext);

	const processedData = processFeaturesSectionData(data, page);
	const { imageArray, title, backgroundColor } = processedData;
	return (
		<>
			<div className='feature-container' style={{ backgroundColor: backgroundColor }}>
				<div className='gatsby-custom-container'>
					<div className='inner-container'>
						<h2 className='features-title-text' style={{ color: title.color }}>
							{title.text}
						</h2>
						<div className='features-section-all-images-container'>
							{imageArray.map((image, index) => {
								return (
									image && (
										<div className='features-image' key={index}>
											<GatsbyImage image={image.imageFile} alt={image.altText} />
										</div>
									)
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default FeaturesSection;
