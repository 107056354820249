import React from 'react';
import './lib.js';
import './style.css';

export default function ZupeeVideo(props) {
    const { type, source, poster } = props;
    return (
        <>
            <div
                dangerouslySetInnerHTML={{ __html: `<zupee-video type=${type} source=${source} poster=${poster}></zupee-video>` }}
                style={{
                    'maxWidth': '768px',
                    'margin': 'auto',
                }}>
            </div>
        </>
    )
}