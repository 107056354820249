import { VideoSectionProps } from '../../../types/types';

const processVideoSectionData = (data: any, page: string): VideoSectionProps => {
	const myData = data.allWpPage.edges[0].node[page][`${page}Videosection`] ?? data.allWpPage.edges[0].node[page][`${page}Utubesection`];

	const videoSectionData: VideoSectionProps = {
		titleText: myData?.titletext,
		backgroundColor: myData?.background?.color,
		videoUrl: myData?.videourl,
		videoPoster: myData?.videoposter
	};
	return videoSectionData;
};
export default processVideoSectionData;
