import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

import '../../styles.css';
import { getApkDynamiClick, osDetector } from '../../utils/downloadLinkUtils';
import { gaOnLoad } from '../../utils/redirectCheck';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';
import FaqSection from '../components/sections/faq';
import FeaturesSection from '../components/sections/features';
import MainDescription from '../components/sections/heroSection';
import TestimonialSection from '../components/sections/testimonial';
import VideoSection from '../components/sections/videoSection';
import { ANALYTICS_CONSTANTS, OperatingSystem } from '../constants';
import PageProvider from '../context/page/PageProvider';

const MujhseKyaHoPaega = ({ data }) => {
	const [apkPromise, setApkPromise] = useState('');
	const [isAndroid, setAndroid] = useState(true);

	useEffect(() => {
		setApkPromise(getApkDynamiClick());
	}, []);

	useEffect(() => {
		if (osDetector() === OperatingSystem.ANDROID) {
			setAndroid(true);
		} else if (osDetector() === OperatingSystem.IOS) {
			setAndroid(false);
		}
	}, []);

	// GA event (unique user & number of session) for onLoad
	useEffect(() => gaOnLoad(), []);

	return (
		<PageProvider
			data={data}
			page='zupeenewyearcampaign'
			apkPromise={apkPromise}
			pageRelatedTo='zupeenewyearcampaign'
			gatsbyDownloadBtnEvent='testGatsby'>
			<div className='zupeenewyearcampaign m5design'>
				<CommonPageRenderer
					fbEventName={ANALYTICS_CONSTANTS.FB_EVENT_HOME_PAGE}
					pageName={ANALYTICS_CONSTANTS.ZUPEE_NEW_YEAR_CAMPAIGN}
					pageRelatedToProps='zupeenewyearcampaign'>
					<MainDescription />
					<FeaturesSection />
					<TestimonialSection />
					{isAndroid && <VideoSection type="youtube" zupeePlayer={true}/>}
					<FaqSection />
				</CommonPageRenderer>
			</div>
		</PageProvider>
	);
};

export const query = graphql`
	{
		allWpPage(filter: { uri: { eq: "/zupee-new-year-campaign/" } }) {
			edges {
				node {
					id
					zupeenewyearcampaign {
						zupeenewyearcampaignSeofields {
							canonicaltag
							description
							robots
							fieldGroupName
							keywords
							title
							structuredschemadata {
								structuredjson
							}
							favicon {
								localFile {
									url
								}
							}
						}
						zupeenewyearcampaignHerosection {
							background {
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
								color
							}
							imagewithtext {
								description {
									text
									color
								}
								image {
									alttext
									imagefile {
										localFile {
											url
										}
									}
								}
								description2 {
									text
									color
								}
							}
							subtitletext {
								color
								text
							}
							titletext {
								color
								text
							}
							downloadbuttondata {
								buttoniosimage {
									alttext
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
								buttonandroidlink
								buttonandroidimage {
									alttext
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
								button {
									buttoncolor
									buttontext {
										color
										text
									}
								}
								buttonioslink
								placeholdertext {
									text
									color
								}
							}
							imageslider {
								scrolltime
								sliders {
									image {
										alttext
										imagefile {
											localFile {
												childImageSharp {
													gatsbyImageData
												}
											}
										}
									}
								}
							}
							smherosectionbanner {
								alttext

								imagefile {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
						}
						zupeenewyearcampaignFeaturessection {
							background {
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
								color
							}
							title {
								color
								text
							}
							imagearray {
								image {
									alttext
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
							}
						}
						zupeenewyearcampaignTestimonials {
							wontextbackgroundcolor
							bordercolor
							background {
								color
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
							downloadandroidimage {
								url
								image {
									alttext
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
							}
							ratingimage {
								alttext
								imagefile {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
							downloadiosimage {
								url
								image {
									alttext
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
							}
							titletext {
								text
								color
							}
							subtitletext {
								text
								color
							}
							winnertitle {
								text
								color
							}
							sliderbutton {
								lefticon {
									color
									image {
										alttext
										imagefile {
											localFile {
												childImageSharp {
													gatsbyImageData
												}
											}
										}
									}
								}
								backgroundcolor
								righticon {
									color
									image {
										alttext
										imagefile {
											localFile {
												childImageSharp {
													gatsbyImageData
												}
											}
										}
									}
								}
							}
							usertestimonials {
								testimonial {
									wontext {
										color
										text
									}
									testimonialtext {
										color
										text
									}
									userdescription {
										location {
											color
											fieldGroupName
											text
										}
										name {
											color
											text
										}
										profilepicture {
											alttext
											imagefile {
												localFile {
													childImageSharp {
														gatsbyImageData
													}
												}
											}
										}
									}
								}
							}
						}
						zupeenewyearcampaignFaq {
							background {
								color
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
							faqs {
								faq {
									descriptiontext {
										color
										text
									}
									titletext {
										color
										text
									}
								}
							}
							mobilebackground
							accordianimagedown {
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
							accordianimageup {
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
							titletext {
								color
								text
							}
						}
						zupeenewyearcampaignUtubesection {
							background {
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
								color
							}
							titletext {
								text
								color
							}
							videourl
							videoposter
						}
						
					
						iosdownloadlink
					}
				}
			}
		}
	}
`;
export default MujhseKyaHoPaega;
